// Stripe.setPublishableKey('pk_live_63rbXFuczhZPG1za5CKCfZa6');
// Stripe.setPublishableKey('pk_test_U9yhWI5Gh8RMFn9pEPLcqT4D00udNyo14K');
var formData = new FormData();
var dataInfo = '';
var fDetailsUrl = [];
var fileUrl = [];
var skillArr = [];
var files = '';
var newFileList = [];
var newFileList2 = [];
var s3 = '';
var bucketName = ''
var bucketRegion = 'us-east-1';
var IdentityPoolId = '';
var mainObj = {
	defaultPartner: 'wsuite',
	defaultService: 'plan',
	defaultBrandreference: '731570',
	env_type: 'stg-',
	env_api_url: 'https://ENVservices-api.wsuite.com/PARTNER-SERVICE',
	cookieDomain: 'destiny.ws',
	defaultLang: 'en',
	WADMIN_URL: 'https://ENVadmin.wsuite.com',
	YOU_URL: 'https://ENVyou.wsuite.com/',
	WTRACK_URL: 'https://ENVtrack.wsuite.com/',
	PLAN_URL: 'https://ENVplan.wsuite.com/',
	DESTINY_URL: 'www.ENVdestiny.ws/',
	BUCKETNAME: 'ENVstatic.wsuite.com',
	IDENTITY_POOL_ID: '',
	client_id: '',
	AMPLIFY_API: 'https://cognito-idp.us-east-1.amazonaws.com',
	cdnPrefixUrl: 'stage-static.wsuite.com',
	calculatedDeliveryDate: null,
	calculatedDeliveryDays: 0
};


var freelancerInfo = {
	fname: '',
	lname: '',
	email: '',
	userLang: mainObj.defaultLang,
	country: '',
	state: '',
	mobile: '',
	jobDesc: '',
	exp_year: '',
	job_id: '',
	skill_rate: '',
	portfolio_urls: '',
}

var userInfo = {
	type: 'autologin',
	s: mainObj.defaultPartner,
	fn: '',
	sn: '',
	e: '',
	p: '',
	com: '',
	pn: '',
	dob: '',
	userLang: mainObj.defaultLang,
	udd: '',
	udt: '',
	sdd: '',
	pid: [],
	spid: [],
	brand_referance: mainObj.defaultBrandreference,
	total_hour: 0,
	total_price: 0,
	campaign: {
		"campaignId": 0,
		"product_template_firstid": 0,
		"quotation_number": 0
	},
	card_name: '',
	card_number: '',
	card_cvv: '',
	card_exp: '',
	OTPDetail: {
		OTP: '',
		sessionID: ''
	}
};
var ajaxResult = [];
var tPrice = [];
var tHours = [];
var tQuantity = [];
var childName = [];
var childDescription = [];
var packageListArr = [];
var cachingArray = new Map();
var exitingpackageListArr = [];

const COUNTRY_CODE = [
	{
		"code": "44",
		"name": "+44"
	}, {
		"code": "1",
		"name": "+1"
	}, {
		"code": "213",
		"name": "+213"
	}, {
		"code": "376",
		"name": "+376"
	}, {
		"code": "244",
		"name": "+244"
	}, {
		"code": "1264",
		"name": "+1264"
	}, {
		"code": "1268",
		"name": "+1268"
	}, {
		"code": "54",
		"name": "+54"
	}, {
		"code": "374",
		"name": "+374"
	}, {
		"code": "297",
		"name": "+297"
	}, {
		"code": "61",
		"name": "+61"
	}, {
		"code": "43",
		"name": "+43"
	}, {
		"code": "994",
		"name": "+994"
	}, {
		"code": "1242",
		"name": "+1242"
	}, {
		"code": "973",
		"name": "+973"
	}, {
		"code": "880",
		"name": "+880"
	}, {
		"code": "1246",
		"name": "+1246"
	}, {
		"code": "375",
		"name": "+375"
	}, {
		"code": "32",
		"name": "+32"
	}, {
		"code": "501",
		"name": "+501"
	}, {
		"code": "229",
		"name": "+229"
	}, {
		"code": "1441",
		"name": "+1441"
	}, {
		"code": "975",
		"name": "+975"
	}, {
		"code": "591",
		"name": "+591"
	}, {
		"code": "387",
		"name": "+387"
	}, {
		"code": "267",
		"name": "+267"
	}, {
		"code": "55",
		"name": "+55"
	}, {
		"code": "673",
		"name": "+673"
	}, {
		"code": "359",
		"name": "+359"
	}, {
		"code": "226",
		"name": "+226"
	}, {
		"code": "257",
		"name": "+257"
	}, {
		"code": "855",
		"name": "+855"
	}, {
		"code": "237",
		"name": "+237"
	}, {
		"code": "1",
		"name": "+1"
	}, {
		"code": "238",
		"name": "+238"
	}, {
		"code": "1345",
		"name": "+1345"
	}, {
		"code": "236",
		"name": "+236"
	}, {
		"code": "56",
		"name": "+56"
	}, {
		"code": "86",
		"name": "+86"
	}, {
		"code": "57",
		"name": "+57"
	}, {
		"code": "269",
		"name": "+269"
	}, {
		"code": "242",
		"name": "+242"
	}, {
		"code": "682",
		"name": "+682"
	}, {
		"code": "506",
		"name": "+506"
	}, {
		"code": "385",
		"name": "+385"
	}, {
		"code": "53",
		"name": "+53"
	}, {
		"code": "90392",
		"name": "+90392"
	}, {
		"code": "357",
		"name": "+357"
	}, {
		"code": "42",
		"name": "+42"
	}, {
		"code": "45",
		"name": "+45"
	}, {
		"code": "253",
		"name": "+253"
	}, {
		"code": "1809",
		"name": "+1809"
	}, {
		"code": "1809",
		"name": "+1809"
	}, {
		"code": "593",
		"name": "+593"
	}, {
		"code": "20",
		"name": "+20"
	}, {
		"code": "503",
		"name": "+503"
	}, {
		"code": "240",
		"name": "+240"
	}, {
		"code": "291",
		"name": "+291"
	}, {
		"code": "372",
		"name": "+372"
	}, {
		"code": "251",
		"name": "+251"
	}, {
		"code": "500",
		"name": "+500"
	}, {
		"code": "298",
		"name": "+298"
	}, {
		"code": "679",
		"name": "+679"
	}, {
		"code": "358",
		"name": "+358"
	}, {
		"code": "33",
		"name": "+33"
	}, {
		"code": "594",
		"name": "+594"
	}, {
		"code": "689",
		"name": "+689"
	}, {
		"code": "241",
		"name": "+241"
	}, {
		"code": "220",
		"name": "+220"
	}, {
		"code": "7880",
		"name": "+7880"
	}, {
		"code": "49",
		"name": "+49"
	}, {
		"code": "233",
		"name": "+233"
	}, {
		"code": "350",
		"name": "+350"
	}, {
		"code": "30",
		"name": "+30"
	}, {
		"code": "299",
		"name": "+299"
	}, {
		"code": "1473",
		"name": "+1473"
	}, {
		"code": "590",
		"name": "+590"
	}, {
		"code": "671",
		"name": "+671"
	}, {
		"code": "502",
		"name": "+502"
	}, {
		"code": "224",
		"name": "+224"
	}, {
		"code": "245",
		"name": "+245"
	}, {
		"code": "592",
		"name": "+592"
	}, {
		"code": "509",
		"name": "+509"
	}, {
		"code": "504",
		"name": "+504"
	}, {
		"code": "852",
		"name": "+852"
	}, {
		"code": "36",
		"name": "+36"
	}, {
		"code": "354",
		"name": "+354"
	}, {
		"code": "91",
		"name": "+91"
	}, {
		"code": "62",
		"name": "+62"
	}, {
		"code": "98",
		"name": "+98"
	}, {
		"code": "964",
		"name": "+964"
	}, {
		"code": "353",
		"name": "+353"
	}, {
		"code": "972",
		"name": "+972"
	}, {
		"code": "39",
		"name": "+39"
	}, {
		"code": "1876",
		"name": "+1876"
	}, {
		"code": "81",
		"name": "+81"
	}, {
		"code": "962",
		"name": "+962"
	}, {
		"code": "7",
		"name": "+7"
	}, {
		"code": "254",
		"name": "+254"
	}, {
		"code": "686",
		"name": "+686"
	}, {
		"code": "850",
		"name": "+850"
	}, {
		"code": "82",
		"name": "+82"
	}, {
		"code": "965",
		"name": "+965"
	}, {
		"code": "996",
		"name": "+996"
	}, {
		"code": "856",
		"name": "+856"
	}, {
		"code": "371",
		"name": "+371"
	}, {
		"code": "961",
		"name": "+961"
	}, {
		"code": "266",
		"name": "+266"
	}, {
		"code": "231",
		"name": "+231"
	}, {
		"code": "218",
		"name": "+218"
	}, {
		"code": "417",
		"name": "+417"
	}, {
		"code": "370",
		"name": "+370"
	}, {
		"code": "352",
		"name": "+352"
	}, {
		"code": "853",
		"name": "+853"
	}, {
		"code": "389",
		"name": "+389"
	}, {
		"code": "261",
		"name": "+261"
	}, {
		"code": "265",
		"name": "+265"
	}, {
		"code": "60",
		"name": "+60"
	}, {
		"code": "960",
		"name": "+960"
	}, {
		"code": "223",
		"name": "+223"
	}, {
		"code": "356",
		"name": "+356"
	}, {
		"code": "692",
		"name": "+692"
	}, {
		"code": "596",
		"name": "+596"
	}, {
		"code": "222",
		"name": "+222"
	}, {
		"code": "269",
		"name": "+269"
	}, {
		"code": "52",
		"name": "+52"
	}, {
		"code": "691",
		"name": "+691"
	}, {
		"code": "373",
		"name": "+373"
	}, {
		"code": "377",
		"name": "+377"
	}, {
		"code": "976",
		"name": "+976"
	}, {
		"code": "1664",
		"name": "+1664"
	}, {
		"code": "212",
		"name": "+212"
	}, {
		"code": "258",
		"name": "+258"
	}, {
		"code": "95",
		"name": "+95"
	}, {
		"code": "264",
		"name": "+264"
	}, {
		"code": "674",
		"name": "+674"
	}, {
		"code": "977",
		"name": "+977"
	}, {
		"code": "31",
		"name": "+31"
	}, {
		"code": "687",
		"name": "+687"
	}, {
		"code": "64",
		"name": "+64"
	}, {
		"code": "505",
		"name": "+505"
	}, {
		"code": "227",
		"name": "+227"
	}, {
		"code": "234",
		"name": "+234"
	}, {
		"code": "683",
		"name": "+683"
	}, {
		"code": "672",
		"name": "+672"
	}, {
		"code": "670",
		"name": "+670"
	}, {
		"code": "47",
		"name": "+47"
	}, {
		"code": "968",
		"name": "+968"
	}, {
		"code": "680",
		"name": "+680"
	}, {
		"code": "507",
		"name": "+507"
	}, {
		"code": "675",
		"name": "+675"
	}, {
		"code": "595",
		"name": "+595"
	}, {
		"code": "51",
		"name": "+51"
	}, {
		"code": "63",
		"name": "+63"
	}, {
		"code": "48",
		"name": "+48"
	}, {
		"code": "351",
		"name": "+351"
	}, {
		"code": "1787",
		"name": "+1787"
	}, {
		"code": "974",
		"name": "+974"
	}, {
		"code": "262",
		"name": "+262"
	}, {
		"code": "40",
		"name": "+40"
	}, {
		"code": "7",
		"name": "+7"
	}, {
		"code": "250",
		"name": "+250"
	}, {
		"code": "378",
		"name": "+378"
	}, {
		"code": "239",
		"name": "+239"
	}, {
		"code": "966",
		"name": "+966"
	}, {
		"code": "221",
		"name": "+221"
	}, {
		"code": "381",
		"name": "+381"
	}, {
		"code": "248",
		"name": "+248"
	}, {
		"code": "232",
		"name": "+232"
	}, {
		"code": "65",
		"name": "+65"
	}, {
		"code": "421",
		"name": "+421"
	}, {
		"code": "386",
		"name": "+386"
	}, {
		"code": "677",
		"name": "+677"
	}, {
		"code": "252",
		"name": "+252"
	}, {
		"code": "27",
		"name": "+27"
	}, {
		"code": "34",
		"name": "+34"
	}, {
		"code": "94",
		"name": "+94"
	}, {
		"code": "290",
		"name": "+290"
	}, {
		"code": "1869",
		"name": "+1869"
	}, {
		"code": "1758",
		"name": "+1758"
	}, {
		"code": "249",
		"name": "+249"
	}, {
		"code": "597",
		"name": "+597"
	}, {
		"code": "268",
		"name": "+268"
	}, {
		"code": "46",
		"name": "+46"
	}, {
		"code": "41",
		"name": "+41"
	}, {
		"code": "963",
		"name": "+963"
	}, {
		"code": "886",
		"name": "+886"
	}, {
		"code": "7",
		"name": "+7"
	}, {
		"code": "66",
		"name": "+66"
	}, {
		"code": "228",
		"name": "+228"
	}, {
		"code": "676",
		"name": "+676"
	}, {
		"code": "1868",
		"name": "+1868"
	}, {
		"code": "216",
		"name": "+216"
	}, {
		"code": "90",
		"name": "+90"
	}, {
		"code": "7",
		"name": "+7"
	}, {
		"code": "993",
		"name": "+993"
	}, {
		"code": "1649",
		"name": "+1649"
	}, {
		"code": "688",
		"name": "+688"
	}, {
		"code": "256",
		"name": "+256"
	}, {
		"code": "380",
		"name": "+380"
	}, {
		"code": "971",
		"name": "+971"
	}, {
		"code": "598",
		"name": "+598"
	}, {
		"code": "7",
		"name": "+7"
	}, {
		"code": "678",
		"name": "+678"
	}, {
		"code": "379",
		"name": "+379"
	}, {
		"code": "58",
		"name": "+58"
	}, {
		"code": "84",
		"name": "+84"
	}, {
		"code": "84",
		"name": "+1284"
	}, {
		"code": "84",
		"name": "+1340"
	}, {
		"code": "681",
		"name": "+681"
	}, {
		"code": "969",
		"name": "+969"
	}, {
		"code": "967",
		"name": "+967"
	}, {
		"code": "260",
		"name": "+260"
	}, {
		"code": "263",
		"name": "+263"
	}
];
